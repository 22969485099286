import type { Callback, Dict, RequestOptions } from "mixpanel-browser"

import mixpanel from "mixpanel-browser"

// Init

const mixpanelState = { hasInitialized: false }

export const mixpanelInit = () => {
  if (!mixpanelState.hasInitialized) {
    mixpanel.init(window.ENV.PUBLIC_MIXPANEL_TOKEN!, {
      track_pageview: "url-with-path-and-query-string",
      persistence: "localStorage", // Switch this to "cookie" if we want to track cross-subdomain
    })

    mixpanelState.hasInitialized = true
  }
}

// Events

const EVENT_NAMES = {
  DISCOUNT_COPIED: "Discount: Copied",
  DISCOUNT_REVEALED: "Discount: Revealed",
  DISCOUNT_CTA_CLICK: "Discount: CTA Click",
  TAP_SUCCESSFUL: "Tap: Successful",
  TAP_FAILED: "Tap: Failed",
  BEAM_PLAY: "Beam: Play",
  AUDIO_DOWNLOAD: "Audio: Download",
  AUDIO_STREAM: "Audio: Stream",
  LINK_CLICK: "Link: Click",
  PRODUCT_VIEWED: "Product: Viewed",
  POLL_RESPONSE_SUBMITTED: "Poll: Submit Response",
  FORCED_CLAIM_LANDED: "Forced Claim: Landed",
  FORCED_CLAIM_ACTIVATE_ITEM_CLICK: "Forced Claim: Activate Item Click",
  FORCED_CLAIM_SUCCESS: "Forced Claim: Successful and Closed",
  INSTANT_CLAIM_CONFIRM_CLICK: "Instant Claim: Confirm Click",
} as const

type EventName = keyof typeof EVENT_NAMES

type EventProperties = {
  DISCOUNT_COPIED: { "Discount Code ID": number }
  DISCOUNT_REVEALED: { "Discount Code ID": number }
  DISCOUNT_CTA_CLICK: { "Discount Code ID": number }
  TAP_SUCCESSFUL: { "Tap ID": string }
  TAP_FAILED: { error: string }
  POLL_RESPONSE_SUBMITTED: {
    "Poll ID": string
    "Option ID"?: string
    "Is Open Response": boolean
  }
  BEAM_PLAY: { "Beam ID": string; beamType: "takeover" | "featured" | "normal" }
  AUDIO_DOWNLOAD: { "Audio ID": string }
  AUDIO_STREAM: { "Audio ID": string; "Track ID": string }
  LINK_CLICK: { "Link ID": string }
  PRODUCT_VIEWED: {
    "Product Handle": string
    "Referral Source"?: string
    Referrer?: string
    "Is User Referrer"?: boolean
  }
  FORCED_CLAIM_LANDED: {
    "Leased Tag ID": number
  }
  FORCED_CLAIM_ACTIVATE_ITEM_CLICK: {
    "Leased Tag ID": number
  }
  FORCED_CLAIM_SUCCESS: {
    "Leased Tag ID": number
  }
  INSTANT_CLAIM_CONFIRM_CLICK: {
    "Leased Tag ID": number
  }
}

// Track

export function track<T extends EventName>(
  event: T,
  properties: EventProperties[T] & Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
) {
  const mixpanelEventName = EVENT_NAMES[event]
  return mixpanel.track(mixpanelEventName, properties, optionsOrCallback, callback)
}

export const register = (...args: Parameters<typeof mixpanel.register>) =>
  mixpanel.register(...args)

export function track_links<T extends EventName>(
  query: Parameters<typeof mixpanel.track_links>[0],
  event_name: T,
  properties: (el: HTMLAnchorElement) => EventProperties[T] & Dict,
) {
  const mixpanelEventName = EVENT_NAMES[event_name]
  return mixpanel.track_links(query, mixpanelEventName, properties)
}
